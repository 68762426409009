import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "visualizacao-de-dados",
      "style": {
        "position": "relative"
      }
    }}>{`visualização de dados`}</h1>
    <p>{`A visualização de dados consiste em apresentar graficamente informações e dados para sintetizar informações complexas e compreender padrões e exceções apresentados nos dados. A visualização de dados não deve ser usada como decoração.`}</p>
    <TabLinks items={[{
      title: 'Modelos',
      href: '/design-guidelines/data-visualization/models'
    }, {
      title: 'Uso',
      href: '/design-guidelines/data-visualization/usage'
    }]} mdxType="TabLinks" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      